import Compare from "./Compare.mts";
import UArrayLike from "./UArrayLike.mts";
import UFunction from "./UFunction.mts";
import UMap from "./UMap.mts";
import UObject from "./UObject.mts";
import UTuple from "./UTuple.mts";
import UType from "./UType.mts";

namespace UArray {
  export type Maybe<T> = T | T[];

  export type Map<A extends any[], R> = A extends [any, ...infer Rest]
    ? [R, ...Map<Rest, R>]
    : R[];
  export const map = <A extends any[], R>(
    arr: A,
    mapFn: (value: A[number], index: number, array: A) => R,
  ) => arr.map(mapFn as any) as Map<A, R>;

  export const sliceAfter = <A extends any[]>(
    arr: A,
    predicate: Parameters<A["findIndex"]>[0],
  ) => arr.slice(arr.findIndex(predicate) + 1 || Infinity);

  export const includesLiteral = <A extends readonly any[], U>(
    array: A,
    searchElement: U,
    fromIndex?: number,
  ): searchElement is A[number] & U => array.includes(searchElement, fromIndex);

  export function partition<A extends any[]>(
    arr: A,
    predicate: Parameters<A["find"]>[0],
  ) {
    const res: [A[number][], A[number][]] = [[], []];

    for (let i = 0; i < arr.length; ++i) {
      const item = arr[i]!;
      res[predicate(item, i, arr) ? 0 : 1].push(item);
    }

    return res;
  }

  export type CutAt<T extends any[], I extends number> = UTuple.CutAt<T, I>;
  export const cutAt = <A extends any[], I extends number>(arr: A, i: I) =>
    [arr.slice(0, i), arr.slice(i)] as CutAt<A, I>;

  export type WithLengthOf<A extends any[], Length extends number> = A extends A
    ? UType.Narrow<
        CutAt<A, Length> extends [infer Left extends any[], any]
          ? [...UTuple.Required<Left>]
          : never,
        A
      >
    : never;
  export namespace WithLengthOf {
    export type AtLeast<A extends any[], Length extends number> = UType.Narrow<
      CutAt<A, Length> extends [
        infer Left extends any[],
        infer Right extends any[],
      ]
        ? [...UTuple.Required<Left>, ...Right]
        : never,
      A
    >;
  }
  export function hasLengthOf<A extends any[], Length extends number>(
    arr: A,
    length: Length,
  ): arr is WithLengthOf<A, Length> {
    return arr.length === length;
  }
  hasLengthOf.atLeast = <A extends any[], const Length extends number>(
    arr: A,
    length: Length,
  ): arr is WithLengthOf.AtLeast<A, Length> => arr.length >= length;

  export function assertLengthOf<A extends any[], Length extends number>(
    arr: A,
    length: Length,
    message = `Assertion failed: Length of array is not ${length}` as UFunction.Maybe<
      [A],
      string
    >,
  ) {
    if (arr.length !== length) throw new Error(UFunction.unwrap(message, arr));
    return arr as WithLengthOf<A, Length>;
  }
  assertLengthOf.atLeast = <A extends any[], const Length extends number>(
    arr: A,
    length: Length,
    message = `Assertion failed: Length of array is less than ${length}` as UFunction.Maybe<
      [A],
      string
    >,
  ): WithLengthOf.AtLeast<A, Length> => {
    if (arr.length < length) throw new Error(UFunction.unwrap(message, arr));
    return arr as WithLengthOf.AtLeast<A, Length>;
  };

  export const zip = <A1, A2>(arr1: A1[], arr2: A2[]) =>
    [
      ...arr1.flatMap((item, i) =>
        i < arr2.length ? [item, arr2[i]] : [item],
      ),
      ...arr2.slice(arr1.length),
    ] as (A1 | A2)[];

  export const join = UArrayLike.join;

  export const countItems = <T,>(arr: T[]) => {
    const map = new Map<T, number>();

    for (const item of arr) {
      map.set(item, (map.get(item) || 0) + 1);
    }

    return map;
  };

  export function equal(arr1: any[], arr2: any[]) {
    return (
      arr1.length === arr2.length && arr1.every((value, i) => value === arr2[i])
    );
  }
  equal.unordered = (arr1: any[], arr2: any[]) =>
    UMap.equal(countItems(arr1), countItems(arr2));

  export function maxBy<T>(
    arr: T[],
    selector: Compare.Selector<T>,
  ): T | undefined;
  export function maxBy<T>(
    arr: T[],
    comparator: Compare.Comparator<T>,
  ): T | undefined;
  export function maxBy<T>(
    arr: T[],
    selectorOrComparator: Compare.Selector<T> | Compare.Comparator<T>,
  ): T | undefined;
  export function maxBy<T>(
    arr: T[],
    selectorOrComparator: Compare.Selector<T> | Compare.Comparator<T>,
  ): T | undefined {
    const comparator = Compare.comparator(selectorOrComparator);

    const iter = arr[Symbol.iterator]();
    let max = iter.next().value;

    for (const item of iter) {
      if (comparator(item, max as T) > 0) max = item;
    }

    return max;
  }

  export const dedupe = <T extends any>(...arrs: Maybe<T>[]) =>
    [...new Set(arrs.flat())] as T[];

  export const dedupeBy = <T,>(array: T[], select: UObject.Select<T>) => {
    const selector = UObject.createSelector(select);
    const set = new Set();
    return array.filter((item) => {
      const res = selector(item as any);
      if (set.has(res)) return;
      set.add(res);
      return true;
    });
  };
}

export default UArray;
